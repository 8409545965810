.root {
  height: calc(100% - 48px);
  :global {
    .search-card {
      // height: 80px;
      border-radius: 8px;
    }
    .ant-form-item {
      font-size: 16px;
      margin-bottom: 0;
      // height: 68px;
    }
  }
}
