.root {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    :global {
        .concent {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // flex-wrap: wrap;
            height: calc(100% - 40px);
            width: calc(100% - 40px);
            overflow-y: auto;
            background: #fff;
            margin: 20px;
            border-radius: 10px;

            .ant-spin-nested-loading {
                width: 100%;
                height: 100%;

                .ant-spin {
                    max-height: 100%;
                }
            }

            .contentDiv {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .contentBox {
                width: 1200px;
                // height: 100%;

                .licenseListBox {
                    position: relative;
                    // top: -20px;
                    left: -20px;
                    width: 100%;
                    height: 450px;
                    // background: red;
                    // padding: 10px 20px;
                    display: flex;
                    padding-top: 20px;
                    margin-top: 20px;
                    flex-wrap: wrap;
                    overflow-y: auto;

                    .subCard {
                        height: calc(100% - 20px);
                        aspect-ratio: 1 / 1.255;
                        margin-bottom: 20px;
                        margin-top: 0;

                        .itemLabel {
                            width: 80px;
                        }

                        .itemTitle {
                            width: calc(100% - 90px);
                        }
                    }
                }

                .productTitle {
                    width: 100%;
                    text-align: left;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    color: #313131;
                    // height: 60px;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                }

                #tj {
                    margin-top: 0;
                    height: 66px;
                }

                .msgBox {
                    width: 100%;
                    text-align: left;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    color: #313131;
                    margin: 50px 0 20px 0;
                }

                .userMsgBoxContent {
                    .userMsgBox {
                        height: 70px;
                        margin-top: 60px;

                        img {
                            display: inline-block;
                            height: 66px;
                            width: auto;
                            margin-top: 18px;
                        }

                        .userMsg {
                            margin-left: 20px;
                        }

                        .userMsgTitle {
                            span {
                                color: #0068B2;
                            }
                        }
                    }

                    .licenseListBox2 {
                        width: 100%;
                        height: 105px;
                        padding: 24px;
                        padding-bottom: 0;
                        display: flex;
                        align-items: center;

                        .licenseItemBox {
                            height: 100%;
                            position: relative;
                            padding: 8px;
                            margin-left: 10px;
                            cursor: pointer;

                            .topIcon {
                                position: absolute;
                                top: 0;
                                right: 0;
                            }

                            .licenseType {
                                width: 100%;
                                height: 18px;
                                line-height: 18px;
                                font-size: 10px;
                                color: #006AB2;

                            }

                            .licenseDate {
                                width: 100%;
                                font-size: 10px;
                                height: 18px;
                                line-height: 18px;
                                color: #9E9E9E;
                                text-wrap: nowrap;
                            }

                            .licenseVersion {
                                width: 100%;
                                font-size: 14px;
                                height: 22px;
                                line-height: 22px;
                                color: #313131;
                                font-weight: bold;
                            }

                            .licenseLine {
                                width: 14px;
                                height: 2px;
                                border-radius: 2px;
                                background: #9E9E9E;
                                margin: 3px 0;
                            }

                            &:nth-of-type(1) {
                                background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
                                color: rgba(122, 186, 253, 0.5);
                                border-radius: 4px 4px 4px 4px;
                                margin-left: 0;
                            }

                            &:nth-of-type(2) {
                                background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
                                color: rgba(255, 145, 166, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }

                            &:nth-of-type(3) {
                                background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
                                color: rgba(122, 186, 253, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }

                            &:nth-of-type(4) {
                                background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
                                color: rgba(255, 145, 166, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }

                            &:nth-of-type(5) {
                                background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
                                color: rgba(122, 186, 253, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }

                            &:nth-of-type(6) {
                                background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
                                color: rgba(255, 145, 166, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }

                            &:nth-of-type(7) {
                                background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
                                color: rgba(122, 186, 253, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }

                            &:nth-of-type(8) {
                                background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
                                color: rgba(255, 145, 166, 0.5);
                                border-radius: 4px 4px 4px 4px;
                            }
                        }

                        box-sizing: border-box;
                    }
                }

                .userMsgBox {
                    height: 130px;
                    // width: 70%;
                    margin-top: 80px;
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;

                    .userMsg {
                        margin-left: 40px;
                        text-align: left;

                        .userMsgTitle {
                            font-size: 20px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: bold;
                            color: #313131;
                        }

                        .userMsgText {
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            color: #313131;
                            margin-top: 5px;

                            &>span {
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                color: #006AB2;
                                text-decoration: underline;
                                margin-left: 10px;
                                cursor: help;
                            }
                        }
                    }
                }
            }

            .proListBox {
                width: 100%;
                height: 140px;
                background: rgba(108, 174, 255, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;

                .proListBoxContent {
                    width: 1200px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;

                    // background: #313131;
                    .proListItem {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        font-weight: bold;
                        color: #313131;
                        cursor: pointer;

                        img {
                            display: block;
                            height: 80%;
                            width: auto;
                            max-width: 100%;
                        }

                        // &:hover {
                        //     background: #0068B2;
                        //     color: #fff;
                        // }
                    }

                    .proListItemActive {
                        background: rgba($color: #006bb2, $alpha: 0.2);
                        color: #fff;
                        justify-content: start;
                    }

                    .proListItems {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        font-size: 20px;
                        font-weight: bold;
                        color: #313131;
                        cursor: pointer;

                        img {
                            display: block;
                            height: 105px;
                            width: auto;
                            max-width: 100%;
                        }

                        .saleSolutionNameDiv {
                            width: 100%;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 14px;
                            color: #535353;
                            line-height: 30px;
                            border-top: 1px solid #FFFFFF;

                            span {
                                cursor: pointer;
                                user-select: none;
                            }

                            .activeSpan {
                                color: #0068B2 !important;
                            }

                            .line {
                                height: 24px;
                                width: 1px;
                                background-color: #ffffff;
                            }
                        }
                    }

                    .proListItemsActive {
                        background: rgba($color: #006bb2, $alpha: 0.2);
                        color: #fff;
                        justify-content: start;
                    }

                }
            }

            .proTableBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px 0;
                // min-height: calc(100% - 400px);

                .proTable {
                    width: 1200px;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;

                    .ant-divider-vertical {
                        height: unset;
                        width: 2px;
                        top: 0;
                        border-left: 1px dashed #D8D8D8;
                        margin: 0 6px;
                    }

                    .proTableItemBox {
                        // height: 800px;
                        // background: #006AB2;

                        .proTableItemTitleBox {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            width: 100%;
                            height: 150px;
                            border-radius: 8px 8px 8px 8px;
                            opacity: 1;
                            border: 1px solid #D8D8D8;
                            position: relative;
                            box-sizing: border-box;
                            padding: 10px 14px;

                            .title {
                                font-size: 20px;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: bold;
                                color: #313131;
                            }

                            .freeSpan {
                                position: absolute;
                                top: -1px;
                                right: -1px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 48px;
                                height: 28px;
                                background: #FE545F;
                                border-radius: 0px 8px 0px 8px;
                                color: #fff;
                                font-size: 12px;
                                font-family: PingFang SC, PingFang SC;
                                color: #FFFFFF;
                            }

                            .proTableItemName {
                                width: 100%;
                                font-size: 16px;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: bold;
                                color: #313131;
                            }

                            .proTableItemMsg {
                                width: 100%;
                                font-size: 12px;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                color: #313131;
                            }

                            .proTableItemBtnBox {
                                width: 100%;
                                height: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                span {
                                    font-size: 18px;
                                    font-family: Century Gothic, Century Gothic;
                                    font-weight: bold;
                                    color: #006AB2;

                                    span {
                                        font-size: 10px;
                                        margin-right: 2px;
                                    }
                                }

                                .successBtn {
                                    color: #16D8D8;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 18px;
                                    font-weight: bolder;

                                    span {
                                        color: #16D8D8 !important;
                                        font-size: 24px !important;
                                        margin-right: 5px !important;
                                    }
                                }

                                .freeBtn {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 80px;
                                    height: 30px;
                                    background: #FEC054;
                                    border-radius: 4px 4px 4px 4px;
                                    cursor: pointer;
                                    color: #fff;
                                    font-size: 14px;
                                    user-select: none;
                                }

                                .contactBtn {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 80px;
                                    height: 30px;
                                    background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                                    border-radius: 4px 4px 4px 4px;
                                    cursor: pointer;
                                    color: #fff;
                                    font-size: 14px;
                                    user-select: none;
                                }
                            }
                        }

                        .proTableItemListBox {
                            width: 100%;
                            margin-top: 20px;
                            color: #0068B2;
                            background: rgba(108, 174, 255, 0.1);
                            border-radius: 4px;

                            div {
                                width: 100%;
                                height: 56px;
                                text-align: center;
                                font-size: 14px;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0 10px;
                                // line-height: 50px;
                            }
                        }
                    }

                    .proTableItemBoxFree {
                        .proTableFreeItemTitleBox {
                            background: rgba(254, 192, 84, 0.1);
                            border-color: #FEC054;

                            .proTableItemBtnBox {
                                span {
                                    color: #FEC054;
                                }
                            }
                        }

                        .proTableItemListBox {
                            background: rgba(254, 192, 84, 0.1);
                            color: #B9801B;
                        }
                    }
                }
            }
        }
    }
}

:global {
    .myCardPopover {
        padding-top: 5px;
        padding-left: 5px;
        width: 320px;
        max-height: 400px;

        .ant-popover-arrow {
            display: none;
        }

        .ant-popover-inner {
            border-radius: 4px;
            box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
        }
    }

    .commModal {
        .ant-modal-body {
            padding: 0;
        }

        .commModalContent {
            width: 100%;
            height: 550px;
            display: flex;
            align-items: center;
            justify-content: center;

            .commModalLeft {
                width: 50%;
                height: 100%;
                background: #F5F9FD;
                border-radius: 10px 0px 0px 10px;
                padding: 40px;
                position: relative;

                .commModalLeftContent {
                    width: 100%;
                    height: calc(100% - 50px);
                    // margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    flex-direction: column;

                    span {
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #313131;
                        margin: 20px 0;
                    }

                    .qr {
                        display: block;
                        height: 200px;
                        width: 200px;
                    }

                    .kf {
                        display: block;
                        height: 40px;
                        width: 100px;
                        // width: auto;
                        cursor: pointer;
                    }
                }

                .userIdBox {
                    position: absolute;
                    bottom: 22px;
                    left: 40px;
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E9E9E;
                }
            }

            .commModalRight {
                width: 50%;
                height: 100%;
                border-radius: 0px 10px 10px 0px;
                padding: 40px 60px;

                .commModalRightContent {
                    width: 100%;
                    height: calc(100% - 50px);
                    margin-top: 20px;

                    .ant-form-item {
                        margin-right: 0px !important
                    }

                    .formBtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        // margin-top: 40px;
                        background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                        border-radius: 4px 4px 4px 4px;
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }

            .titleBox {
                font-size: 18px;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                color: #222222;
            }
        }
    }
}