.root {
  height: calc(100% - 48px);
  :global {
    .ant-card ant-card-bordered {
      height: 100%;
    }
    .ant-tabs-nav {
      padding: 8px 24px 0px 24px;
    }
    .log-search-card {
      padding: 0px 24px;
      border-bottom: 8px solid #f7f7f7;
    }
    .ant-card-body {
      height: 100%;
      padding: 0px;
    }
    .ant-tabs-content {
      height: 100%;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 10px;
    }
    .log_table {
      padding: 24px;
    }
    .log_table .ant-table-content table > tbody {
      padding-left: 10px !important;
    }
    .log-search-card .ant-form-item {
      margin-bottom: 16px;
    }
    .space-btn {
      // padding-bottom: 16px;
    }
    .ant-card-bordered {
      border: none;
    }
  }
}
