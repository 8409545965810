.headerContainer {
  .left-title-box {
    display: flex;
    align-items: center;

    .moreProjectBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40;
      height: 100%;
      font-size: 24px;
      padding: 0 0 0 24px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #006ab2;
      }
    }

    .left-title {
      font-size: 16px;
      color: #313131;
      margin: 0px 12px 0px 24px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

      .logoImg {
        display: inline-block;
        height: 28px;
      }
    }

    .typeBox {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #222222;
    }

    .authTimeBox {
      margin-left: 20px;
      user-select: none;

      a,
      .adminBox {
        color: #0068b2;
        margin: 0 4px;
      }
    }
  }

  .ant-layout-header {
    padding: 0;
    height: 48px;
    line-height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-header-right {
    float: right;
    margin-right: 23px;
    display: flex;
    align-items: center;

    .typeBtnBox {
      .typeBtn {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        height: 26px !important;
        line-height: 24px !important;
        padding: 0 6px !important;
        cursor: pointer;
        color: #fff;
        background: #0068b2;
        box-shadow: 0 2px 0 rgba(8, 128, 168, 0.15);
        font-size: 14px;
        border-radius: 2px;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        border: 1px solid transparent;
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        user-select: none;
        touch-action: manipulation;
        box-sizing: border-box;

        .anticon {
          margin-right: 8px;
        }

        &:hover {
          background: #1f82bf;
        }
      }
    }

    .menuItem {
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

.userInfoCardPopover {
  padding-top: 8px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    width: 296px;
    padding: 0px;

    .userInfoCardContent {
      width: 100%;
      height: 100%;

      .userLogoContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        padding: 24px;
        box-sizing: border-box;
        z-index: 2;

        .userNameBox {
          // width: 100%;
          height: 24px;
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #222222;
          margin: 8px 0;
          z-index: 2;
          position: relative;

          .userRoleTag {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            box-sizing: border-box;
            top: -4px;
            right: 0px;
            height: 16px;
            min-width: 50px;
            padding: 0 4px;
            font-size: 10px;
            font-weight: 400;
            color: #ff5252;
            border: #ff5252 1px solid;
            border-radius: 20px;
            transform: translateX(100%);
          }
        }

        .userTagBox {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          background: linear-gradient(45deg, #006ab2 0%, #16d8d8 100%);
          border-radius: 28px;
          padding: 1px 6px;
          // cursor: pointer;
          z-index: 2;
          user-select: none;
        }

        .userLogoBg {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          opacity: 0.1;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }

        .userCoNameBox {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #9e9e9e;
          font-size: 14px;
          margin-top: 10px;
          z-index: 100;

          .anticon {
            cursor: pointer;
            margin-left: 6px;

            .icon_xia {
              margin-bottom: 4px;
            }

            &:hover {
              color: #006ab2;
            }
          }
        }
      }
    }

    .licenseListBox {
      width: 100%;
      height: 105px;
      padding: 24px;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .licenseItemBox {
        height: 100%;
        position: relative;
        padding: 8px;
        
        .topIcon {
          position: absolute;
          top: 0;
          right: 0;
        }

        .licenseType {
          width: 100%;
          height: 18px;
          line-height: 18px;
          font-size: 10px;
          color: #006AB2;

        }

        .licenseDate {
          width: 100%;
          font-size: 10px;
          height: 18px;
          line-height: 18px;
          color: #9E9E9E;
          text-wrap: nowrap;
        }

        .licenseVersion {
          width: 100%;
          font-size: 14px;
          height: 22px;
          line-height: 22px;
          color: #313131;
          font-weight: bold;
        }

        .licenseLine{
          width: 14px;
          height: 2px;
          border-radius: 2px;
          background: #9E9E9E;
          margin: 3px 0;
        }

        &:nth-of-type(1) {
          background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
          color: rgba(122, 186, 253, 0.5);
          border-radius: 4px 4px 4px 4px;
        }

        &:nth-of-type(2) {
          background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
          color: rgba(255, 145, 166, 0.5);
          border-radius: 4px 4px 4px 4px;
        }
      }

      box-sizing: border-box;
    }

    .personalMenusContent {
      width: 100%;
      padding: 24px;
      padding-top: 16px;
      box-sizing: border-box;

      .personalMenusBox {
        width: 100%;

        .personalMenusLine {
          width: 100%;
          height: 1px;
          background: #d8d8d8;
          margin: 8px 0;
          // margin-bottom: 16px;
        }

        .personalMenusItem {
          width: 100%;
          // margin-bottom: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px 0;
          user-select: none;
          color: #313131;

          &:hover {
            color: #006ab2;
            font-weight: bold;

            .personalMenusItemIcon {
              path {
                fill: #006ab2 !important;
              }
            }
          }

          .personalMenusItemIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;

            div {
              height: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            svg {
              width: 14px;
              height: 14px;
            }

            path {
              fill: #313131 !important;
            }
          }
        }
      }
    }
  }
}

.moreProjectPopover {
  padding-top: 16px;
  padding-left: 8px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    width: 360px;
    padding: 0px;

    .moreProjectPopoverContent {
      width: 100%;
      padding: 20px;
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .projectItemBox {
        width: calc(50% - 10px);
        min-height: 40px;
        margin-top: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          span {
            color: #006ab2;
          }
        }

        img {
          display: inline-block;
          width: 40px;
        }

        span {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          color: #222222;
          margin-left: 10px;
        }
      }
    }
  }
}

.enterpriseDrawer {
  border-radius: 10px 0px 0px 10px;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ant-form-item {
    margin-right: 0px;
  }

  .enterpriseDrawerContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .activeEnterpriseItem {
      .anticon {
        color: #006ab2;
      }

      .labelSpan {
        border: #006ab2 1px solid;
        box-sizing: border-box;

        .anticon {
          display: inline-block !important;
          color: #aad461 !important;
          margin-left: 20px;
        }
      }
    }

    .enterpriseItem {
      width: 100%;
      margin-bottom: 10px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #313131;
      cursor: pointer;

      &:hover {
        .anticon {
          color: #006ab2;
        }

        color: #006ab2;
      }

      .labelSpan {
        padding: 4px 10px;
        margin-left: 10px;

        .anticon {
          display: none;
        }
      }
    }
  }

  .drawerFooterBtnBox {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .ant-btn {
      min-width: 90px;
      margin-left: 10px;
      // color: #fff;
      // background: #016DB4;
      // border-color: #0068B2;
    }

    .noBg {
      // color: #535353;
      // background: #FFFFFF;
      // border-color: #D9D9D9;
    }
  }
}

.headerAdminPopover {
  padding-top: 16px;
  padding-left: 8px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    width: 240px;
    padding: 12px 0;

    .adminPopoverContent {
      max-height: 300px;
      width: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-y: auto;

      .adminPopoverItem {
        width: 100%;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.switchCompModel {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-btn {
        width: 96px;
      }
    }
  }
}