.root {
  height: calc(100% - 48px);
  :global {
    .ant-form-item {
      font-size: 16px;
      margin-bottom: 0;
      // height: 68px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 30px;
    }

    .ant-table-body table {
      padding-left: 10px;
    }

    .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
      padding: 0 !important;
    }
    .tox-statusbar {
      display: none !important;
    }
  }
}
