.root {
  height: calc(100% - 48px);
  :global {
    .form-search {
      display: flex;
      .ant-input::placeholder {
        font-size: 12px;
      }
    }

    .ant-table-content {
      border-radius: 8px;
    }
    .ant-table-thead {
      height: 32px;
    }

    .anticon-exclamation-circle {
      height: 30px;
      width: 30px;
      color: #fe545f;
    }

    .search-card {
      // height: 80px
      padding-right: 24px;
      border-radius: 8px 8px 0px 0px;
      border: none;
      .co_text {
        font-size: 14px;
        padding-top: 24px;
        padding-bottom: 16px;
        font-weight: 800;
      }
    }
    .search-card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }
    .btn_style {
      border: none;
    }
    .ant-table-wrapper {
      padding: 0px 24px;
      border-radius: 0px 0px 8px 8px;
    }
  }
}
