.root {
  height: calc(100% - 48px);
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    // .content_header_card {
    //   height: 80px;
    // }
    // .content_header_card .ant-card-body {
    //   padding: 0px;
    //   padding-left: 24px;
    //   padding-top: 4px;
    // }
  }
}
