.noticeDrawer {
    border-radius: 10px 0px 0px 10px;

    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .typeSreach {
        font-size: 14px;
        font-weight: 400;
        color: #0068B2;
        cursor: pointer;
    }

    .myTabs {
        height: 100%;
        width: 100%;

        .ant-tabs-tabpane {
            height: 100%;
        }

        .ant-tabs-nav {
            margin: 0 0 15px 0;
        }

        .ant-tabs-tab:first-of-type {
            margin-left: 13px;
        }

        .ant-tabs-tab {
            padding: 10px 0px 5px 0;
            margin-left: 50px;
        }

        .ant-tabs-nav-list {
            // padding-left: 20px;
        }

        .ant-tabs-tab-btn {
            color: #9E9E9E;
        }

        .ant-tabs-content-holder {
            height: calc(100% - 30px);
        }

        .ant-tabs-content {
            height: 100%;
        }

        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #006AB2
            }
        }

        .ant-tabs-ink-bar {
            // width: 20px !important;
            background: #006AB2;
        }
    }

    .infiniteScrollBox {
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 4px;

            &:hover {
                background-color: #a8a8a8;
            }

            &:active {
                background-color: #787878;
            }
        }

        & {
            scrollbar-width: thin;
            scrollbar-color: #c1c1c1 #eee;
        }
    }

    .cardBox {
        padding: 0 20px;
        height: 100%;

        .cardItem {
            min-height: 50px;
            border-bottom: 1px dashed #D8D8D8;
            padding: 10px 0;
            display: flex;

            .ant-badge {
                margin-right: 5px;
            }

            &:last-of-type {
                border-bottom: none;
            }

            .top {
                .active {
                    color: #0068B2;
                    margin-left: 10px;
                }
            }

            .bottom {
                .valueItem {
                    width: 50%;
                    margin-right: 5px;
                    display: flex;
                    font-size: 12px;

                    .label {
                        margin-right: 5px;
                        color: #9E9E9E;
                    }

                    .value {
                        color: #535353;
                    }
                }
            }
        }
    }

    .noDataDivider {
        margin: 0;
    }

    .emptyBox {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-empty-description {
            color: #9E9E9E;
        }
    }

    .loadingBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .noticeTabs {
        .ant-tabs-nav {
            width: calc(100% - 40px);
            margin-left: 20px;
        }
    }

    .drawerFooterBtnBox {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .ant-btn {
            min-width: 90px;
            margin-left: 10px;
            // color: #fff;
            // background: #016DB4;
            // border-color: #0068B2;
        }

        .noBg {
            // color: #535353;
            // background: #FFFFFF;
            // border-color: #D9D9D9;
        }
    }
}


.mySpinLoading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .myLoadingIcon {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
    }

    .ant-spin-text {
        color: #9E9E9E;
        font-size: 15px;
    }
}