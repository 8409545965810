.root {
  height: calc(100% - 48px);

  :global {
    .form-search {
      display: flex;

      .ant-input::placeholder {
        font-size: 12px;
      }
    }

    .ant-table-content {
      border-radius: 8px;

    }

    .ant-table-thead {
      height: 32px;

      .paddingHeader {
        padding-left: 15px !important;
      }
    }

    .anticon-exclamation-circle {
      height: 30px;
      width: 30px;
      color: #fe545f;
    }

    .search-card {
      // height: 80px
      padding-right: 24px;
      border-radius: 8px 8px 0px 0px;
      border: none;

      .co_text {
        font-size: 14px;
        padding-top: 24px;
        padding-bottom: 16px;
        font-weight: 800;
      }
    }

    .search-card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }

    .btn_style {
      border: none;
    }

    .btn_list {
      position: absolute;
      left: 0;
      top: 35px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 130px;
      // height: 70px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
      border-radius: 1px 1px 1px 1px;
      opacity: 1;
      padding: 4px;
      z-index: 99;

      a {
        // height: ;
        line-height: 26px;
        cursor: pointer;
        color: #535353;
      }

      a:hover {
        color: #0068b2;
      }
    }

    .ant-table-wrapper {
      padding: 0px 24px;
      border-radius: 0px 0px 8px 8px;
    }

    .ant-select-selection-overflow {
      position: relative;
      display: flex;
      flex: auto;
      flex-wrap: nowrap;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}