.root {
  height: 100%;
  padding: 24px;

  :global {
    .content {
      // text-align: center;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text {
      height: 52px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #313131;
    }
    .text_bottom {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #313131;
    }
  }
}
