body {
    touch-action: manipulation;
}

.wxHtmlContent {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .loginContentBox,
    .registerContent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8%;
        flex-wrap: wrap;

        .ant-result-icon {
            svg {
                width: 23.5vw;
                height: 23.5vw;
                fill: #5ABE6B;
            }
        }

        .ant-result-title {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #222222;
            font-size: 4.8vw;
        }

        .ant-btn {
            width: 100%;
            height: 11.7vw;
            font-size: 4.3vw;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
        }

        .ant-result {
            padding: 0;
        }

        .ant-btn:hover,
        .ant-btn:focus {
            color: #535353;
            border-color: #D8D8D8;
        }

        .ant-btn-primary:hover,
        .ant-btn-primary:focus {
            border-color: #5ABE6B;
            color: #ffffff;
        }

        .loginBtn {
            margin-top: 37.3vw;
            background: #5ABE6B;
            border-radius: 2.1vw;
            color: #ffffff;
        }

        .closeBtn {
            margin-top: 4.3vw;
            border-radius: 2.1vw;
            color: #535353;
            border-color: #D8D8D8;
        }
    }

    .registerContentBox {
        width: 100%;
        // height: 100%;
        display: flex;
        // align-items: center;
        justify-content: center;
        padding: 0 8%;
        flex-wrap: wrap;

        .formBox {
            width: 100%;
            height: auto;
            // aspect-ratio: 1 / 1;
            margin-top: 7vw;

            .ant-form-item {
                margin-right: 0px;
            }

            .ant-form-item-label>label {
                font-size: 3.7vw;
            }

            .ant-form-item-explain-error {
                font-size: 3.7vw;
            }

            .ant-input {
                height: 9.6vw;
                border-radius: 1.1vw;
                font-size: 3.7vw;
            }

            .ant-select,
            .ant-select-selector {
                height: 9.6vw;
                border-radius: 1.1vw;
                font-size: 3.7vw;
                box-sizing: border-box;

                .ant-select-selection-placeholder,
                .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                }

                .ant-select-selection-search-input {
                    height: 9.6vw;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                }
            }

            .checkItem {
                font-size: 12px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #9E9E9E;
                margin-bottom: 12px;
            }

            .ant-btn {
                width: 100%;
                height: 11.7vw;
                font-size: 4.3vw;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
            }

            .ant-btn:hover,
            .ant-btn:focus {
                color: #535353;
                border-color: #D8D8D8;
            }

            .ant-btn-primary:hover,
            .ant-btn-primary:focus {
                border-color: #5ABE6B;
                color: #ffffff;
            }

            .verificationCodeBtn {
                width: 20.3vw;
                height: 9.6vw;
                padding: 4px;
                font-size: 3.2vw;
                box-sizing: border-box;
                background: rgba(0, 106, 178, 0.15);
                border-radius: 1.1vw;
                opacity: 0.8;
                border: 1px solid #006AB2;
                color: #006AB2;
            }

            .verificationCodeBtn:hover,
            .verificationCodeBtn:focus {
                border: 1px solid #006AB2;
                color: #006AB2;
            }

            .creatBtn {
                margin-top: 6.4vw;
                background: #5ABE6B;
                border-radius: 2.1vw;
                color: #ffffff;
            }

            .closeBtn {
                margin-top: 4.3vw;
                border-radius: 2.1vw;
                color: #535353;
                border-color: #D8D8D8;
            }


        }

        .registerContent {
            width: 100%;
            padding: 0;
        }
    }

}