html,
body {
    // min-width: 1000px !important;
    min-height: 760px;
    overflow-y: auto;
    overflow-x: auto;
}

.root {
    width: 100%;
    height: 100%;
    // background: center/cover url(../../assets/bg.png);
    position: relative;

    :global {

        .loginPageHeader {
            width: 100%;
            height: 80px;
            background: rgba(255, 255, 255, 0.8);
            position: absolute;
            top: 0;
            left: 0;
            padding: 15px 30px;
            z-index: 10;
            display: flex;
            align-items: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 28px;
            color: #000000;

            .loginPageLogo {
                display: inline-block;
                height: 100%;
            }

            span {
                display: inline-block;
                height: 28px;
                width: 1px;
                background-color: #000000;
                margin: 0 24px;
            }
        }

        .loginPageFooter {
            width: 100%;
            height: 70px;
            background: #ffffff;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 15px 30px;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .moreProducts {
                flex: none;
                display: flex;
                align-items: center;

                .moreProductsItem {
                    cursor: pointer;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 18px;
                    color: #000000;
                    line-height: 25px;
                    text-align: left;
                    display: flex;
                    align-items: center;

                    &:first-of-type {
                        margin-left: 20px;
                    }

                    &:hover {
                        color: #006AB2;
                    }

                    span {
                        height: 16px;
                        width: 1px;
                        background: #000000;
                        margin: 0 10px;
                    }
                }
            }

            .copyright {
                color: #999999;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                div {
                    margin-left: 4px;
                }
            }

            .contactInformation {
                display: flex;
                align-items: center;
                flex: none;

                span {
                    margin-right: 10px;

                    .footer-media-container {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        border-radius: 16px;
                        margin-right: 5px;
                        background-color: #383838;
                        background-size: 100%;
                    }

                    .footer-media-container:hover {
                        background-color: #4d4d4d;
                    }

                    .footer-wechat {
                        position: relative;
                        background-image: url(../../assets/footer-wechat.svg);
                    }

                    .footer-official-account {
                        position: relative;
                        background-image: url(../../assets/footer-official-account.svg);
                    }

                    .footer-zhihu {
                        background-image: url(../../assets/footer-zhihu.svg);
                    }
                }
            }
        }

        .ant-carousel {
            width: 100%;
            height: calc(100% - 70px);
            position: absolute;
            top: 0;
            left: 0;

            .loginPageCarousel,
            .slick-list {
                width: 100%;
                height: 100%;
            }

            .slick-track {
                height: 100%;
            }

            .slick-slide {
                div {
                    width: 100%;
                    height: 100%;
                }
            }

            .loginPageCarouselItem {
                width: 100%;
                height: 100%;
                // background: #9E9E9E;
                display: flex;
                align-items: center;
                justify-content: center;

                .loginPageCarouselItemBg {
                    width: 100%;
                    height: calc(100% + 70px);
                    // background-size: 100% 100%;
                    // background-repeat: no-repeat;
                    // background-position: center;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-attachment: fixed;
                    background-size: cover;
                }

                img {
                    display: block;
                    // width: 100%;
                    width: auto;
                    height: 100%;
                }
            }
        }

        .contentBoxContent {
            position: absolute;
            top: 80px;
            left: 0;
            height: calc(100% - 150px);
            width: 100%;
            padding: 0 8%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .contentLogoBox {
                img {
                    display: inline-block;
                    max-height: 140px;
                    max-width: 500px;
                }
            }
        }

        .contentBox {

            width: 640px;
            height: 440px;
            background: rgba(255, 255, 255, 0.95);
            border-radius: 10px;
            display: flex;

            .contentLeftBox {
                border-radius: 10px 0 0 10px;
                width: 240px;
                height: 100%;
                // background: url(../../assets/bg2.png);
                // background-size: 100% 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: column;

                .topTitleBox {
                    font-size: 22px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    // color: #FFFFFF;
                    margin-top: 50px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .lightImg {
                        display: block;
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }

                .lineBox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 14px;

                    .line {
                        width: 36px;
                        height: 4px;
                        background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                        border-radius: 4px 4px 4px 4px;

                    }
                }

                .qrBox {
                    width: 70%;
                    aspect-ratio: 1 / 1;
                    background: #FFFFFF;
                    margin-top: 60px;
                    border-radius: 4px;
                    position: relative;
                    overflow: hidden;

                    .overdueBox {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        svg {
                            fill: #ffffff;
                        }
                    }

                    .imgLoadingBox {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0, 0, 0, 0.6);
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                .qrTip {
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    margin-top: 20px;
                    color: #999999;
                }
            }

            .contentRightBox {
                width: 400px;
                height: 100%;
                display: flex;
                align-items: center;
                // flex-wrap: wrap;
                flex-direction: column;

                .topTitleBox {
                    font-size: 22px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #222222;
                    margin-top: 50px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .lightImg {
                        display: block;
                        width: 30px;
                        // height: 60px;
                        margin-right: 10px;
                    }

                }

                .lineBox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 14px;

                    .line {
                        width: 36px;
                        height: 4px;
                        background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                        border-radius: 4px 4px 4px 4px;

                    }
                }

                .formBox {
                    width: 76%;
                    height: auto;
                    // aspect-ratio: 1 / 1;
                    margin-top: 32px;

                    .ant-input-number-handler-wrap {
                        display: none;
                    }

                    .ant-form-item {
                        margin-right: 0px;
                    }

                    // .ant-form-item-explain-error{
                    //     font-size: 12px;
                    // }

                    .checkItem {
                        font-size: 12px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #9E9E9E;
                        margin-bottom: 12px;
                    }

                    .verificationCodeBtn {
                        width: 76px;
                        padding: 4px;
                        font-size: 12px;
                        box-sizing: border-box;
                        background: rgba(0, 106, 178, 0.15);
                        border-radius: 4px 4px 4px 4px;
                        opacity: 0.8;
                        border: 1px solid #006AB2;
                        color: #006AB2;
                    }

                    .verificationCodeBtn:hover,
                    .verificationCodeBtn:focus {}



                }

                .upBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 76%;
                    height: 40px;
                    background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                    border-radius: 4px 4px 4px 4px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 12px;
                    cursor: pointer;
                }

                .tipBpx {
                    width: 100%;
                    // width: 55%;
                    // text-align: left;
                    margin-top: 24px;
                    // padding-left: 8px;
                    font-size: 12px;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #999999;
                }

                .formTip {
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E9E9E;
                    margin-top: 4%;
                    display: flex;

                    span {
                        cursor: pointer;
                        color: #006AB2;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .anticon {
                            font-size: 8px;
                            font-weight: bold;
                        }
                    }
                }
            }

            .aImg {
                position: absolute;
                display: block;
                height: 28%;
                left: -3%;
                bottom: -5px;
            }
        }

    }
}

// 媒体查询
@media screen and (max-width: 1350px) {

    html,
    body {
        // min-width: 1000px !important;
    }

    .root {
        :global {
            .contentBoxContent {
                padding: 0 4% !important;
            }

            .contentBox {
                // width: 800px;
                // height: 500px;


                .topTitleBox {
                    margin-top: 13% !important;
                }

                .contentRightBox {

                    .formBox,
                    .upBtn,
                    .tipBpx {
                        width: 70%;
                    }
                }
            }
        }
    }
}

// @media screen and (max-width: 950px) {

//     html,
//     body {
//         min-width: 760px !important;
//         overflow-x: auto;
//     }

//     .root {
//         :global {

//             .contentBox {
//                 right: calc(50% - 320px);

//                 .contentRightBox {

//                     .ant-form-item {
//                         margin-bottom: 14px;
//                     }

//                     .tipBpx {
//                         white-space: unset;
//                         margin-top: 14px;
//                     }

//                     .formBox,
//                     .upBtn,
//                     .tipBpx {
//                         width: 80%;
//                     }

//                 }
//             }
//         }
//     }
// }

@media screen and (max-width: 600px) {

    html,
    body {
        min-width: unset !important;
        // min-height: 155vw;
        min-height: 800px;
    }

    .root {
        background: center/cover url(../../assets/bg-2.png);
        min-width: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;

        :global {
            .contentBoxContent {
                padding: 0;
                top: 0;
                left: 0;
                height: 100%;
                justify-content: center;
            }

            .contentLogoBox {
                display: none;
            }

            .loginPageCarousel {
                display: none;
            }

            .logoImg {
                display: none;
            }

            .loginPageHeader {
                display: none;
            }

            .loginPageFooter {
                display: none;
            }

            .contentBox {
                max-width: unset;
                max-height: unset;
                min-width: unset;
                min-height: unset;
                width: calc(100% - 48px);
                height: calc(100% - 48px);
                flex-direction: column;
                top: unset !important;
                right: unset !important;

                .aImg {
                    // display: none;
                    height: 60px;
                    top: calc(42% - 60px);
                    left: 0;
                }

                .contentLeftBox {
                    width: 100%;
                    height: 45%;
                    border-radius: 10px 10px 0 0;
                    background: url(../../assets/bg3.png);
                    background-size: 100% 100%;
                    justify-content: center;

                    .qrBox {
                        max-width: 20vh;
                        margin-top: 4vh;
                    }

                    .topTitleBox {
                        margin-top: 0% !important;

                        .line {
                            left: 52px;
                        }
                    }

                }

                .contentRightBox {
                    width: 100%;
                    height: 55%;
                    justify-content: center;

                    .tipBpx {
                        white-space: nowrap !important;
                    }

                    .topTitleBox {
                        .line {
                            left: 66px;
                        }
                    }

                    .formBox {
                        margin-top: 3vh;
                    }

                    .formBox,
                    .upBtn,
                    .tipBpx {
                        width: 80%;
                    }
                }

                .topTitleBox {
                    margin-top: 0% !important;
                }
            }
        }
    }
}

:global {
    .bindModal {
        .ant-modal-body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .bindModelForm {
                width: 100%;

                .ant-form-item {
                    width: 100%;
                    margin-right: 0px;
                    display: flex;
                    flex-direction: row;
                    // align-items: center;
                    justify-content: center;

                    .ant-form-item-row {
                        width: 100%;
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: row;
                    }

                    .ant-form-item-label {
                        padding: 0 20px 0 0;
                        flex: none;
                        display: flex;
                        align-items: center;
                        height: 32px;
                    }

                    .ant-form-item-control {
                        flex: auto;
                    }
                }
            }
        }


        .bindDiv {
            width: 100%;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 12px;
        }

        .tipsDiv {
            width: 100%;
            font-size: 14px;
            margin-bottom: 30px;
        }

        .verificationCodeBtn {
            width: 76px;
            padding: 4px;
            font-size: 12px;
            box-sizing: border-box;
            background: rgba(0, 106, 178, 0.15);
            border-radius: 4px 4px 4px 4px;
            opacity: 0.8;
            border: 1px solid #006AB2;
            color: #006AB2;
        }

        .verificationCodeBtn:hover,
        .verificationCodeBtn:focus {}

        .upBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55%;
            height: 40px;
            background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
            border-radius: 4px 4px 4px 4px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 12px;
            cursor: pointer;
            user-select: none;
        }
    }

    .footerIconPopover {
        .ant-popover-inner-content {
            padding: 4px;
        }
    }

    .footerIconPopoverBox {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}