.root {
  height: calc(100% - 40px);
  :global {
    .contanier {
      height: calc(100% - 24px);
      background-color: #ffff;
      padding: 40px 20%;
      .ant-tabs-content-holder {
        overflow: auto;
      }
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
    .right_content {
      position: relative;
    }
    .unsubscribe_btn {
      position: absolute;
      bottom: 24px;
      left: calc(50% - 100px);
    }

    .comp .personal_div:nth-child(2) {
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid #efefef;
    }
    .comp .personal_div:nth-child(3) {
      padding-bottom: 0px;
      border-bottom: none;
    }
    .personal_div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: baseline;
    }
    .personal_div:first-child {
      padding-top: 40px;
      padding-bottom: 20px;
      align-items: center;
      position: relative;
    }
    .personal_div:first-child::before {
      content: '账户信息';
      position: absolute;
      top: 0px;
      left: 0px;
      font-weight: 800;
    }
    .personal_div:nth-child(2) {
      padding-bottom: 16px;
      border-bottom: 1px solid #efefef;
    }
    .comp {
      .personal_div:nth-child(2) {
        padding-bottom: 16px;
        border-bottom: none;
      }
      .personal_div:nth-child(3) {
        position: relative;
        padding-top: 0px;
      }
    }
    .personal_div:nth-child(3) {
      position: relative;
      padding-top: 50px;
    }
    .personal_div:nth-child(3)::before {
      content: attr(co_name);
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 800;
    }
    .personal_div:nth-child(3)::after {
      content: attr(enterprise_id);
      position: absolute;
      top: 0;
      right: 0;
      color: #b1b1b1;
    }
    .personal_input {
      width: 40%;
      height: 32px;
    }

    .personal_list {
      width: 20%;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC-粗体, PingFang SC;
      font-weight: normal;
      color: #313131;
      line-height: 14px;
    }

    .personal_info {
      width: 50%;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #535353;
      // line-height: 14px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .personal_setting a {
      width: 10%;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #0068b2;
      line-height: 14px;
      margin-left: 16px;
    }
    .personal_info img {
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
    .tip_text {
      width: 78px;
      height: 7px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #006ab2;
      line-height: 7px;
      margin-left: 2px;
    }
    .card_item {
      height: 120px;
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-start;
    }
    .card_item:first-child {
      margin-top: 24px;
    }
    .co_card_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .co_text {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #313131;
    }
    .co_version {
      width: 60px;
      height: 21px;
      background: linear-gradient(45deg, #006ab2 0%, #16d8d8 100%);
      border-radius: 28px 28px 28px 28px;
      opacity: 1;
      font-size: 8px;
      color: #fff;
      line-height: 21px;
      text-align: center;
    }
    .co_user_name {
      color: #535353;
      font-size: 13px;
    }
    .ant-form-item {
      align-items: inherit;
      height: 100%;
    }
    .verificationCodeBtn {
      width: 76px;
      padding: 4px;
      font-size: 12px;
      box-sizing: border-box;
      background: rgba(0, 106, 178, 0.15);
      border-radius: 4px 4px 4px 4px;
      opacity: 0.8;
      border: 1px solid #006ab2;
      color: #006ab2;
    }
    .ant-modal-footer {
      border: none;
    }
  }
}
