.root {
  height: 100%;
  text-align: center;
  padding: 24px;
  :global {
    .content_div {
      height: 100%;
      background-color: #fff;
      padding-top: 40px;
    }
    .content_form {
      padding: 0 35%;
      padding-top: 40px;
      text-align: left;
    }
    .ant-form-item {
      align-items: inherit;
      height: 100%;
      margin-bottom: 16px;
    }
    .submit_btn {
      background-color: #5abe6b;
      color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    height: 100%;
    text-align: center;
    padding: 24px;
    :global {
      .content_div {
        height: 100%;
        background-color: #fff;
        padding-top: 40px;
      }
      .content_form {
        padding: 0;
        padding-top: 24px;
        text-align: left;
      }
      .ant-form-item {
        align-items: inherit;
        height: 100%;
        margin-bottom: 16px;
      }
      .submit_btn {
        background-color: #5abe6b;
        color: #fff;
      }
    }
  }
}
