.root {
  height: calc(100% - 48px);

  :global {
    .cardBox {
      width: 100%;
      height: calc(100% - 10px);
      border-radius: 10px;
      background: #fff;

      .btnBox {
        height: 70px;
        width: 100%;
        padding: 20px;

        .ant-btn {
          width: 120px !important;
          float: right;
          margin-left: 20px;
        }

        .ant-upload {
          float: right;
        }
      }

      .licenseListBox {
        width: 100%;
        height: calc(100% - 80px);
        // background: red;
        // padding: 10px 20px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        

      }

      .noLicenseBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 80px);
        padding: 0 10px;

        .ant-empty-image {
          height: 150px;
        }

        .ant-empty-description {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

:global {
  .licenseModal {
    .licenseModalContent {
      width: 100%;

      .licenseTitle {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #313131;
        font-weight: bold;
        margin-bottom: 10px;

        img {
          display: inline-block;
          // height: 16px;
          width: 28px;
          margin-right: 4px;
        }
      }

      .licenseText,
      .licenseEsnText {
        padding-left: 32px;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .licenseEsnText {
        color: #006AB2;
        margin-bottom: 20px;

        span {
          cursor: pointer;
          margin-left: 10px;
          // line-height: 24px;
          text-decoration: underline;
        }
      }
    }
  }
}