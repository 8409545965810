.root {
    height: 100%;

    :global {
        .subCard {
            height: calc(100% - 20px);
            aspect-ratio: 1 / 1.255;// 2 / 3;
            margin-left: 20px;
            margin-top: 20px;
            // margin-right: 10px;
            overflow-x: hidden;
            // background: rgba($color: #000000, $alpha: 0.2);
            display: flex;
            // align-items: center;
            // justify-content: space-evenly;
            flex-wrap: wrap;
            box-shadow: 0px 8px 32px 0px rgba(0, 50, 85, 0.1);
            border-radius: 4px 4px 4px 4px;
            position: relative;

            .headerLogo {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 20px;
                height: 60px;

                img {
                    height: 30px;
                    width: auto;
                }

                .ant-typography {
                    margin-top: 10px;
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    line-height: 14px;
                    -webkit-line-clamp: 2;
                    background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                }
            }

            .licenseItemsBox {
                width: 100%;
                height: calc(100% - 170px);

                .licenseItem {
                    width: 100%;
                    padding: 0 24px;
                    margin-top: 10px;

                    .itemBox {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 12px;
                        font-family: PingFang SC, PingFang SC;
                        color: #535353;

                        .ant-typography {
                            margin-bottom: 0;
                        }

                        .itemTitle {
                            width: 50%;
                            overflow: hidden;
                        }

                        .itemLabel {
                            width: calc(50% - 10px);
                            margin-left: 10px;
                            overflow: hidden;

                            .ant-typography {
                                color: #006AB2;
                                text-align: right;
                            }
                        }
                    }

                    .ant-progress {
                        height: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 4px;

                        .ant-progress-outer {
                            height: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .moreBox {
                    width: 100%;
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E9E9E;
                    margin-top: 10px;
                    text-align: center;

                    span {
                        cursor: pointer;
                        user-select: none;

                        &:hover {
                            color: #006AB2;
                        }
                    }
                }
            }

            .btnBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;

            }

            .deleteBox {
                position: absolute;
                bottom: 20px;
                right: 20px;
                color: #9E9E9E;
                font-size: 16px;
                cursor: pointer;

                &:hover {
                    color: #006AB2;
                }
            }
        }
    }
}

:global {
    .subCardModal {
        .subCardModalBox {
            width: 100%;
            aspect-ratio: 2/3;
            max-height: 80vh;

            .headerLogo {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 20px;
                height: 60px;

                img {
                    height: 30px;
                    width: auto;
                }

                .ant-typography {
                    margin-top: 10px;
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    line-height: 14px;
                    -webkit-line-clamp: 2;
                    background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .licenseItemsBox {
                width: 100%;
                height: calc(100% - 70px);
                max-height: 600px;
                overflow-y: auto;

                .licenseItem {
                    width: 100%;
                    padding: 0 24px;
                    margin-top: 10px;

                    .itemBox {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 12px;
                        font-family: PingFang SC, PingFang SC;
                        color: #535353;

                        .ant-typography {
                            margin-bottom: 0;
                        }

                        .itemTitle {
                            width: 70%;
                            overflow: hidden;
                        }

                        .itemLabel {
                            width: calc(30% - 10px);
                            margin-left: 10px;
                            overflow: hidden;


                            .ant-typography {
                                color: #006AB2;
                                text-align: right;
                            }
                        }
                    }

                    .ant-progress {
                        height: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 4px;

                        .ant-progress-outer {
                            height: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .moreBox {
                    width: 100%;
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E9E9E;
                    margin-top: 10px;
                    text-align: center;

                    span {
                        cursor: pointer;
                        user-select: none;

                        &:hover {
                            color: #006AB2;
                        }
                    }
                }
            }
        }
    }
}