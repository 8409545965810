.root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  
  :global {
    .content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      background: #FFFFFF;
      border-radius: 10px;
      // margin: 20px;
      overflow-y: auto;

      .userMsgBox {
        height: 130px;
        // width: 70%;
        // margin-top: 100px;
        display: flex;
        align-items: center;

        .userMsg {
          margin-left: 40px;
          text-align: left;

          .userMsgTitle {
            font-size: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            color: #313131;
          }

          .userMsgText {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #313131;
            margin-top: 5px;

            &>span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #9E9E9E;
            }
          }

          .prodMsgText {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #313131;
            margin-top: 5px;

            .prodMsgTextTitle {
              font-size: 20px;
              font-weight: bold;

              span {
                color: #0068B2;
              }
            }

            &>span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #9E9E9E;
            }
          }

          .adminBox {
            display: inline-block;
            margin: 0 5px;
            text-decoration: underline;
          }
        }
      }

      .showPlanUserMsgBox{
        height: 70px;
        img{
          display: inline-block;
          height: 70px;
        }
        .userMsg{
          margin-left: 20px;
        }
      }

      .productTitle {
        width: 100%;
        text-align: left;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #313131;
        height: 30px;
        margin-top: 20px;
      }

      .productListBox {
        width: 100%;
        min-height: 300px;
        height: calc(80% - 50px);
        margin-top: 20px;
        display: flex;

        .productCard {
          height: 100%;
          aspect-ratio: 2 / 3;
          margin-right: 20px;
          position: relative;

          img {
            display: block;
            height: 100%;
            width: 100%;
          }

          .productCardBtn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            aspect-ratio: 3 / 1;
            background: #16D8D8;
            border-radius: 4px;
            position: absolute;
            left: 30%;
            bottom: 10%;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      .showPlanProductListBox{
        height: calc(80% - 150px);
      }

      .licenseListBox {
        width: 100%;
        height: 105px;
        padding: 24px 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        text-align: left;

        .licenseItemBox {
          height: 100%;
          position: relative;
          padding: 8px;
          margin-left: 10px;
          cursor: pointer;

          .topIcon {
            position: absolute;
            top: 0;
            right: 0;
          }

          .licenseType {
            width: 100%;
            height: 18px;
            line-height: 18px;
            font-size: 10px;
            color: #006AB2;

          }

          .licenseDate {
            width: 100%;
            font-size: 10px;
            height: 18px;
            line-height: 18px;
            color: #9E9E9E;
            text-wrap: nowrap;
          }

          .licenseVersion {
            width: 100%;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            color: #313131;
            font-weight: bold;
          }

          .licenseLine {
            width: 14px;
            height: 2px;
            border-radius: 2px;
            background: #9E9E9E;
            margin: 3px 0;
          }

          &:nth-of-type(1) {
            background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
            color: rgba(122, 186, 253, 0.5);
            border-radius: 4px 4px 4px 4px;
            margin-left: 0;
          }

          &:nth-of-type(2) {
            background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
            color: rgba(255, 145, 166, 0.5);
            border-radius: 4px 4px 4px 4px;
          }

          &:nth-of-type(3) {
            background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
            color: rgba(122, 186, 253, 0.5);
            border-radius: 4px 4px 4px 4px;
          }

          &:nth-of-type(4) {
            background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
            color: rgba(255, 145, 166, 0.5);
            border-radius: 4px 4px 4px 4px;
          }

          &:nth-of-type(5) {
            background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
            color: rgba(122, 186, 253, 0.5);
            border-radius: 4px 4px 4px 4px;
          }

          &:nth-of-type(6) {
            background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
            color: rgba(255, 145, 166, 0.5);
            border-radius: 4px 4px 4px 4px;
          }

          &:nth-of-type(7) {
            background: linear-gradient(270deg, rgba(122, 186, 253, 0.2) 0%, rgba(164, 238, 255, 0.2) 100%);
            color: rgba(122, 186, 253, 0.5);
            border-radius: 4px 4px 4px 4px;
          }

          &:nth-of-type(8) {
            background: linear-gradient(55deg, rgba(255, 145, 166, 0.15) 0%, rgba(255, 198, 131, 0.15) 100%);
            color: rgba(255, 145, 166, 0.5);
            border-radius: 4px 4px 4px 4px;
          }
        }

        box-sizing: border-box;
      }
    }

  }
}

:global {
  .addEnterpriseDrawer {
    .labelDIv {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #313131;
      margin-bottom: 10px;

      span {
        color: #FE545F;
      }
    }
  }
}

:global {
  .adminPopover {
    padding-top: 16px;
    padding-left: 8px;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner-content {
      width: 240px;
      padding: 12px 0;

      .adminPopoverContent {
        max-height: 300px;
        width: 100%;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-y: auto;

        .adminPopoverItem {
          width: 100%;
          margin-bottom: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}