.root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :global {
    .content {
      text-align: center;
    }
    .text {
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 400;
      color: #9e9e9e;
      line-height: 22px;
    }
  }
}
