.root {
  height: calc(100% - 48px);
  :global {
    .ant-form-item {
      font-size: 16px;
      margin-bottom: 0;
      // height: 68px;
    }
    .search-card {
      // height: 80px;
      border-radius: 8px;
    }
    // .search-card .ant-card-body {
    //   padding: 0px;
    //   padding-left: 24px;
    //   padding-top: 4px;
    // }
  }
}
