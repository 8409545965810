.root {
  height: 100%;
  :global {
    .ant-layout-sider {
      background-color: #fff;
    }

    .ant-layout-header {
      z-index: 1;
      padding: 0;
      height: 48px;
      line-height: 48px;
      background-color: #fff;
    }

    .ant-layout-sider .ant-layout-sider-dark {
      overflow: auto;
      overflow-x: hidden;
    }

    img {
      width: 100%;
    }
  }
}
