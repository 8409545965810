.root {
    height: calc(100% - 40px);

    :global {
        .contanier {
            height: calc(100% - 20px);
            background-color: #ffff;
            // padding: 20px;
            border-radius: 10px;
        }

        .right_content {
            position: relative;
        }

        .unsubscribe_btn {
            position: absolute;
            bottom: 24px;
            left: calc(50% - 100px);
        }

        .co_text {
            font-size: 14px;
            font-weight: 800;
            padding: 20px;
            padding-bottom: 0;
        }

        .cardListBox {
            min-height: 300px;
            height: calc(50% - 50px);
            width: 100%;
            // margin-top: 20px;
            padding: 0 5px;
            display: flex;

            .noDataBox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                .ant-empty-image {
                    height: 150px;
                }

                .ant-empty-description {
                    color: rgba(0, 0, 0, 0.25);
                }
            }

            .cardsBox {
                width: 100%;
                height: 100%;
                display: flex;
                overflow-y: auto;
                padding-right: 20px;

                .addCard {
                    height: calc(100% - 40px);
                    aspect-ratio: 1 / 1.255;// 2 / 3;
                    margin-left: 20px;
                    margin-top: 20px;
                    // margin-right: 10px;
                    overflow-x: hidden;
                    // background: rgba($color: #000000, $alpha: 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    box-shadow: 0px 8px 32px 0px rgba(0, 50, 85, 0.1);
                    border-radius: 4px 4px 4px 4px;
                    position: relative;
                    flex-direction: column;
                    cursor: pointer;
                    flex: none;
                    
                    img {
                        display: inline-block;
                        width: 70%;
                        height: auto;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #9E9E9E;
                        text-align: center;
                    }
                }
            }
        }


        .productTitle {
            font-size: 24px;
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            color: #313131;
            height: 40px;
            // margin-top: 10px;
            padding: 0 20px;
        }

        .productListBox {
            min-height: 300px;
            height: calc(50% - 50px);
            width: 100%;
            margin-top: 10px;
            display: flex;
            padding: 0 20px 10px 20px;

            .productCard {
                height: 100%;
                aspect-ratio: 2 / 3;
                margin-right: 20px;
                position: relative;

                img {
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                .productCardBtn {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    aspect-ratio: 3 / 1;
                    background: #16D8D8;
                    border-radius: 4px;
                    position: absolute;
                    left: 30%;
                    bottom: 10%;
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }

    }
}