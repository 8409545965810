.root {
  height: calc(100% - 48px);
  :global {
    // height: 100%;

    // .search-card {
    //   height: 80px;
    //   border-radius: 8px;
    //   overflow: hidden;
    // }
    .form-search {
      display: flex;
      .ant-input::placeholder {
        font-size: 12px;
      }
    }

    .ant-table-content {
      border-radius: 8px;
    }
    .ant-table-thead {
      height: 32px;
    }
    .del {
      color: #787878;
    }
    .edit {
      color: #026fb5;
    }
    .anticon-exclamation-circle {
      height: 30px;
      width: 30px;
      color: #fe545f;
    }
    .is-true {
      height: 22px;
      width: 22px;
      color: #fff;
      border-radius: 50%;
      background-color: #026fb5;
      text-align: center;
    }
    .is-false {
      height: 22px;
      width: 22px;
      color: #fff;
      border-radius: 50%;
      background-color: #b9b9b9;
      text-align: center;
    }
    .search-card {
      // height: 80px
      padding-right: 24px;
      border-radius: 8px 8px 0px 0px;
      border: none;
      .co_text {
        font-size: 14px;
        padding-top: 24px;
        font-weight: 800;
        padding-bottom: 16px;
      }
    }
    .search-card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }
    .btn_style {
      border: none;
    }
    .ant-table-wrapper {
      padding: 0px 24px;
      border-radius: 0px 0px 8px 8px;
    }
  }
}
